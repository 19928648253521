import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { HomeComponent } from './home/home.component';

import { MatInputModule, MatButtonModule, MatSelectModule, MatIconModule, MatFormFieldModule, MatToolbarModule, MatSliderModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListingComponent } from './listing/listing.component';
import { AsesoramientoComponent } from './asesoramiento/asesoramiento.component';
import { MenuComponent } from './menu/menu.component';
import { LoginComponent } from './login/login.component'; 

@NgModule({
  declarations: [
    AppComponent, 
    HomeComponent, ListingComponent, AsesoramientoComponent, MenuComponent, LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,ReactiveFormsModule,    
    MatInputModule, 
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule, MatToolbarModule, MatSliderModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
